.container {
    border-width: 1px;
    border-color: #035fa4;
    border-style: solid;
    padding: 1%;
    width: auto;
  }
  .textBoxContainer {
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .buttonHolder {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .button {
    margin: 0% 2% 2% 0%;
    background-color: #035fa4;
    border: none;
    color: #fff;
    padding: 1%;
    font-weight: 600;
    border-radius: 8px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
    font-size: 16px;
    background-color: #035fa4;
    color: #fff;
  }
  td {
    border: 1px solid black;
    text-align: left;
    padding: 4px;
    font-size: 14px;
    background-color: #bdd3fc;
    color: black;
  }
  tr {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
    font-size: 16px;
    background-color: #bdd3fc;
    color: black;
  }
  tr:nth-child(even) {
    background-color: #fff;
  }
  .searchText {
    font-size: medium;
    font-weight: 600;
  }
  .headerText {
    font-size: 16px;
    font-weight: 700;
    margin: 0% 1% 4% 0%;
  }
  .alert {
    color: #c00000;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
  }
  .alertContainer {
    display: flex;
    justify-content: space-evenly;
  }
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 4px;
  }
  .paginationWrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  