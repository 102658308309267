.mainContainer {
  justify-content: center;
  margin: 5%;

  border-color: #035fa4;
  border-width: 1px;
  border-style: solid;
}
.logoContainer {
  background-color: #035fa4;
  height: 100px;
  margin: 0% 0% 2% 0%;
}
.bottom {
  text-align: "center";
  margin: "10px 0px 10px 0px";
  font-size: "10px";
  color: "#035fa4";
}
